<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
            <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
<!--            <el-form-item label='车辆id' prop='carid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入车辆id' clearable autocomplete="off" v-model='form.carid' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='车辆名称' prop='carname' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入车辆名称' clearable autocomplete="off" v-model='form.carname' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='用户id' prop='userid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.userid' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='用户邮箱' prop='useremail' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入用户邮箱' clearable autocomplete="off" v-model='form.useremail' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='登录邮箱' prop='loginemail' :label-width="formLabelWidth">
              <el-input placeholder='请输入登录邮箱' clearable autocomplete="off" v-model='form.loginemail' size="small"  />
            </el-form-item>
            <el-form-item label='登录密码' prop='loginpwd' :label-width="formLabelWidth">
              <el-input placeholder='请输入登录密码' clearable autocomplete="off" v-model='form.loginpwd' size="small"  />
            </el-form-item>
<!--            <el-form-item label='国家' prop='country' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入国家' clearable autocomplete="off" v-model='form.country' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='商品id' prop='goodsid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入商品id' clearable autocomplete="off" v-model='form.goodsid' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='座位号' prop='seatno' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入座位号' clearable autocomplete="off" v-model='form.seatno' size="small"  />-->
<!--            </el-form-item>-->
            <el-form-item label='车票到期时间' prop='startTime' :label-width="formLabelWidth">
<!--              <el-input placeholder='请输入车票到期时间' clearable autocomplete="off" v-model='form.startTime' size="small"  />-->
                <el-date-picker
                        v-model="form.startTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
<!--            <el-form-item label='是否可用' prop='isuse' :label-width="formLabelWidth">-->
<!--                <el-select v-model="form.isuse" placeholder="请选择">-->
<!--                    <el-option-->
<!--                            v-for="item in isuseOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item label='车票到期时间' prop='endTime' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入车票到期时间' clearable autocomplete="off" v-model='form.endTime' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='赠予时长' prop='giftstimes' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入赠予时长' clearable autocomplete="off" v-model='form.giftstimes' size="small"  />-->
<!--            </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="TicketsdialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form  :rules="rules" ref="ruleUser">
        <el-form-item label='车辆ID'  :label-width="formLabelWidth" >
          <el-input placeholder='请输入车辆ID' clearable autocomplete="off" v-model='TKform.carid' size="small"  />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="updateUserTk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertTicket,updateTicket,deleteTicket,bacthDelTicket,getGoodsSpuAll,disabledTicket,sendExpiredemail,updateUserTicke} from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const endTimeOptions=[
            {
                value:"1",
                label:"是"
            },
            {
                value:"0",
                label:"否"
            },
        ]
        const isuseOptions=[
            {
                value:"1",
                label:"是"
            },
            {
                value:"0",
                label:"否"
            },
        ]
        const typeOptions=[
            {
                value:"1",
                label:"共享"
            },
            {
                value:"2",
                label:"独享"
            },
        ]
        const buytypeOptions=[
            {
                value:"1",
                label:"订阅"
            },
            {
                value:"2",
                label:"兑换码"
            },
            {
                value:"3",
                label:"续费"
            },
        ]
      const columns = [
          // {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'carid',label: '车辆id'},
          {prop: 'carname',label: '车辆名称'},
          {prop: 'userid',label: '用户id'},
          {prop: 'useremail',label: '用户邮箱'},
          {prop: 'loginemail',label: '登录邮箱'},
          {prop: 'loginpwd',label: '登录密码'},
          // {prop: 'orderinfo.buytype',label: '购买类型',formatter: row => this.buytypeState(row)},
          {prop: 'country',label: '国家'},
          {prop: 'isuse',label: '是否可用',formatter: row => this.isuseState(row)},
          {prop: 'goodsid',label: '商品id'},
          {prop: 'goodsSpu.goodsname',label: '商品名称'},
          {prop: 'goodsSpu.type',label: '售卖类型',formatter: row => this.typeState(row)},
          // {prop: 'seatno',label: '座位号'},
          {prop: 'seatno',label: '座位号',formatter: row => row.seatno+1},
          {prop: 'startTime',label: '车票开始时间'},
          {prop: 'endTime',label: '车票到期时间'},
          {prop: 'giftstimes',label: '赠予时长'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
              type: 'select',
              id: 'goodsid',
              label: '商品名称',
              el: {placeholder: '请输入商品名称', clearable: true},
              options:this.spuList
          },
          {
            type: 'input',
            id: 'carid',
            label: '车辆id',
            el: {placeholder: '请输入车辆id', clearable: true},
          },
          {
            type: 'input',
            id: 'carname',
            label: '车辆名称',
            el: {placeholder: '请输入车辆名称', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'useremail',
            label: '用户邮箱',
            el: {placeholder: '请输入用户邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'loginemail',
            label: '登录邮箱',
            el: {placeholder: '请输入登录邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'country',
            label: '国家',
            el: {placeholder: '请输入国家', clearable: true},
          },
          {
            type: 'select',
            id: 'isuse',
            label: '是否可用',
            el: {placeholder: '是否可用', clearable: true},
            options:isuseOptions
          },
          {
            type: 'date-picker',
            id: 'endTime',
            label: '车票到期时间',
            el: {placeholder: '是否临期', clearable: true,valueFormat: 'yyyy-MM-dd'},
          },
      ]
      return {
        columns,
        typeOptions,
        buytypeOptions,
        endTimeOptions,
        isuseOptions,
        userinfo:{},
        spuList:[],
        
        tableConfig: {
          searchForm,
          url: 'ticket/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('ticket:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '发送',
              show:()=>{return this.hasRole('ticket:update')},
              atClick: row => {
                this.sendEmail(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '换车',
              show:()=>{return this.hasRole('ticket:updateTK')},
              atClick: row => {
                this.openChangeTK(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('ticket:delete')},
              atClick: async row => {
                row.carinfo = {}
                row.goodsSpu = {}
                this.handleDelete(row)
                return false
              },
            },
              {
                  type: 'primary',
                  text: '禁用',
                  icon: 'el-icon-error',
                  disabled: (row)=>{
                      if (row.isuse=='0'){
                          return true
                      }else {
                          return false
                      }
                  },
                  show:()=>{return this.hasRole('ticket:disabled')},
                  atClick: async row => {
                      this.handleDisabled(row)
                      return false
                  },
              },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('ticket:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('ticket:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        TKform:{},
        title:"",
        type:"",
        dialogFormVisible:false,
        TicketsdialogFormVisible:false,
        formLabelWidth:"110px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            carid :[
              {required: true, message: '请输入车辆id', trigger: 'blur'},],
            carname :[
              {required: true, message: '请输入车辆名称', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            useremail :[
              {required: true, message: '请输入用户邮箱', trigger: 'blur'},],
            loginemail :[
              {required: true, message: '请输入登录邮箱', trigger: 'blur'},],
            loginpwd :[
              {required: true, message: '请输入登录密码', trigger: 'blur'},],
            country :[
              {required: true, message: '请输入国家', trigger: 'blur'},],
            goodsid :[
              {required: true, message: '请输入商品id', trigger: 'blur'},],
            seatno :[
              {required: true, message: '请输入座位号', trigger: 'blur'},],
            startTime :[
              {required: true, message: '请输入车票开始时间', trigger: 'blur'},],
            endTime :[
              {required: true, message: '请输入车票到期时间', trigger: 'blur'},],
            giftstimes :[
              {required: true, message: '请输入赠予时长', trigger: 'blur'},],
            isuse :[
                {required: true, message: '请选择是否可用', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        isuseState(row){
            let data
            this.isuseOptions.forEach(r=>{
                if (r.value==row.isuse){
                    data=r.label
                }
            })
            return data
        },
        typeState(row){
            let data
            this.typeOptions.forEach(r=>{
                if (r.value==row.goodsSpu.type){
                    data=r.label
                }
            })
            return data
        },
        buytypeState(row){
            let data
            this.buytypeOptions.forEach(r=>{
                if (r.value==row.orderinfo.buytype){
                    data=r.label
                }
            })
            return data
        },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          carid : "", // 车辆id
          carname : "", // 车辆名称
          userid : "", // 用户id
          useremail : "", // 用户邮箱
          loginemail : "", // 登录邮箱
          loginpwd : "", // 登录密码
          country : "", // 国家
          goodsid : "", // 商品id
          seatno : "", // 座位号
          startTime : "", // 车票开始时间
          endTime : "", // 车票到期时间
          giftstimes : "", // 赠予时长
          isuse:"",//是否可用
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
     async sendEmail(row){
        row.carinfo = {}
        row.goodsSpu = {}
        let res = await sendExpiredemail(row.useremail)
        MessageSuccess(res.msg)
      },
      openChangeTK(row){
        this.TicketsdialogFormVisible = true
        this.TKform.id = row.id
      },
      async updateUserTk(){
        let res =  await updateUserTicke(this.TKform.carid,this.TKform.id)
        MessageSuccess(res.msg)
        this.closeDialog()
      },
      handleEdit(row) {
        // row.carinfo.goodsSpu = ''
        // row.goodsSpu.cars = []
        row.carinfo = {}
        row.goodsSpu = {}
        console.log(row)
        this.form = row
				console.log("TCL: handleEdit -> this.form", this.form)
        this.form.startTime=[this.form.startTime,this.form.endTime]
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteTicket(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
        handleDisabled: function (row) {
            this.$confirmel('此操作将永久禁用此车票不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.operatorDisabled(row)
            }).catch(() => {
                MessageInfo("已取消");
            });
        },
      async operatorDisabled(row) {
        row.carinfo = {}
        row.goodsSpu = {}
          let res = await disabledTicket(row)
          if (res.status == "200") {
              MessageSuccess(res.msg)
          } else {
              MessageError(res.msg)
          }
          this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelTicket(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.TicketsdialogFormVisible= false
        this.TKform = {}
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        this.form.endTime=this.form.startTime[1]
        this.form.startTime= this.form.startTime[0]
        if (this.type == "insert") {
          res = await insertTicket(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateTicket(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
        async getGoodsSpuAllInfo() {
            let res = await getGoodsSpuAll()
            if (res.status == "200") {
                this.spuList=[]
                res.data.forEach(r=>{
                    let data={
                        value:r.id,
                        label:r.goodsname
                    }
                    this.spuList.push(data)
                })
                this.tableConfig.searchForm[1].options=this.spuList
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('ticket:update')&&!this.hasRole('ticket:delete')){
          this.tableConfig.hasOperation=false
        }
        if(this.$route.query.id){
          this.tableConfig.url="ticket/list?id="+this.$route.query.id
        }
        this.getGoodsSpuAllInfo()
      }
    }
  }
</script>

<style scoped>

</style>
